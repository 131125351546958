import axios from "axios";
import moment from "moment";
import "rc-slider/assets/index.css";
import EventMenu from "../../eventMenu";
import AlertsTable from "../../alertsTable";
import { Chart } from "./chart";
import { ChartFilter } from "./chartFilter";
import { ChartPaginator } from "./chartPaginator";
import { ALERTS, TRIGGERS } from "../../../store/constants";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { getRangeIconColor, getRangeIconName } from "../../../utils/taskUtils";
import { TIME_SERIES_PRESETS_ONLINE, TIME_SERIES_PRESETS_OFFLINE } from "../../../utils/constants";
import { ImageFitMode, UIOrigins, UIElementBuilders, ImageFill, emptyFill, SolidFill, SolidLine, emptyLine, ColorHEX } from '@arction/lcjs';

const sortAndMergeAlerts = (alerts, feedbacks, triggers) => {
  let allAlerts = [];

  for (let i = 0; i < alerts.length; i++) {
    const { title, time, responseTime, responseTitle } = alerts[i];

    allAlerts.push({ type: "alert", time, title });

    if (responseTime || responseTime === 0)
      allAlerts.push({
        type: "alert response",
        time: responseTime,
        title: responseTitle,
        subject: title
      });
  }

  for (let i = 0; i < feedbacks.length; i++) {
    const { title, time } = feedbacks[i];
    allAlerts.push({ type: "feedback", time, title });
  }

  for (let i = 0; i < triggers.length; i++) {
    const { title, time } = triggers[i];
    allAlerts.push({ type: "trigger", time, title });
  }

  allAlerts.sort((left, right) => left.time - right.time);
  return allAlerts;
}

const channels = [
  "attention",
  "relaxation",
  // "comfort",
  "mental_workload",
  "motion_sickness",
  "stress",
  "vigilance",
];

const channelReprs = new Map([
  ["attention", "Concentration"],
  ["relaxation", "Calmness"],
  ["comfort", "Comfort"],
  ["mental_workload", "Mental workload"],
  ["motion_sickness", "Motion sickness"],
  ["stress", "Stress"],
  ["vigilance", "Situational awareness"],
]);

export const InsightsChart = ({
  id,
  task,
  state,
  socket,
  sendAlert,
  sendTrigger,
  addData,
  dispatch,
  legendData,
  getTaskDetails,
  containerHeight,
  chartRef,
  chart,
  channelSeries
}) => {
  const [alertsFeedbacksList, _setAlertsFeedbacksList] = useState([]);

  const alertsFeedbacksRef = useRef(alertsFeedbacksList);
  const setAlertsFeedbacksRef = (data) => {
    alertsFeedbacksRef.current = data;
    _setAlertsFeedbacksList(data);
  };

  const [isChartReady, setIsChartReady] = useState(false);

  const addChartMarker = (chart, xPosition, iconUrl) => {     
    const image = new Image();
    image.crossOrigin = '';
    image.src = iconUrl;
    image.onload = () => {
        const iconSize = { x: image.width, y: image.height };
        const iconAspectRatio = iconSize.y / iconSize.x;
        const iconSizePx = { x: 25, y: 25 * iconAspectRatio };

        const axisX = chart.getDefaultAxisX()
        const axisY = chart.getDefaultAxisY()

        // Add a vertical line (grid stroke) at the xPosition
        chart
          .getDefaultAxisX()
          .addConstantLine()
          .setValue(xPosition)
          .setStrokeStyle(new SolidLine({ 
              thickness: 1, 
              fillStyle: new SolidFill({ color: ColorHEX("#FFDD66").setA(50) }) 
          }))
          .setEffect(false)
          .setMouseInteractions(false)

        // Create a UI element with the image
        const uiElement = chart
          .addUIElement(UIElementBuilders.TextBox, { x: chart.getDefaultAxisX(), y: chart.getDefaultAxisY() })
          .setPosition({ x: xPosition, y: -0.6 })
          .setOrigin(UIOrigins.LeftBottom)
          .setTextFillStyle(emptyFill)
          .setPadding({ left: iconSizePx.x, top: iconSizePx.y })  // Padding to control size
          .setBackground((background) =>
              background.setStrokeStyle(emptyLine).setFillStyle(
                  new ImageFill({
                      source: image,
                      fitMode: ImageFitMode.Fit,
                  }),
              ),
          )
          .setMouseInteractions(false)

        // Listen for changes in the visible range of the X and Y axes
        const updateVisibility = () => {
          const visibleXRange = axisX.getInterval();
          const visibleYRange = axisY.getInterval();

          // Check if the element is within visible bounds of the chart
          if (xPosition < visibleXRange.start || xPosition > visibleXRange.end ||
              -0.6 < visibleYRange.start || -0.6 > visibleYRange.end) {
            uiElement.setVisible(false);
          } else {
            uiElement.setVisible(true);
          }
        };

        // Trigger the visibility check when the axis intervals change (on scroll or zoom)
        axisX.onIntervalChange(updateVisibility);
        axisY.onIntervalChange(updateVisibility);
    };
  };

  const addAlertChartMarker = (chart, alertTime, iconName, iconColor) => {
    const xPosition = alertTime; 
    const iconUrl = `/images/icons/chart_icons/ic_${iconName}_${iconColor}.svg`;
  
    addChartMarker(chart, xPosition, iconUrl);
  };

  const drawAlertsFeedbacks = useCallback((alertsFeedbacks) => {
    for (const alertFeedbackItem of alertsFeedbacks) {
      const { time, title, type } = alertFeedbackItem;
  
      switch (type) {
        case "alert":
          addAlertChartMarker(
            chart, 
            time, 
            getRangeIconName(title), 
            getRangeIconColor("alertSend")
          );
          break;
  
        case "alert response":
          addAlertChartMarker(
            chart, 
            time, 
            getRangeIconName(alertFeedbackItem.subject), 
            getRangeIconColor(alertFeedbackItem.title)
          );
          break;
  
        case "feedback":
          addAlertChartMarker(
            chart, 
            time, 
            getRangeIconName(title), 
            getRangeIconColor("feedback")
          );
          break;

        case "trigger":
          addAlertChartMarker(
            chart, 
            time, 
            getRangeIconName(title), 
            getRangeIconColor("trigger")
          );
          break;
  
        default:
          break;
      }
    }
  }, [
    addAlertChartMarker
  ]);

  useEffect(() => {
    if (chart && !isChartReady) {
      axios
      .get(`${process.env.REACT_APP_SERVER_URL}/task/${task.objectId}/alerts-feedbacks`)
      .then(({ data: { result: { alerts, feedbacks, triggers } } }) => {
          setIsChartReady(true);
          const alertsFeedbacks = sortAndMergeAlerts(alerts, feedbacks, triggers);
          setAlertsFeedbacksRef(alertsFeedbacks);
          drawAlertsFeedbacks(alertsFeedbacks);
        })
        .catch((error) => console.log(error));
    }
  }, [
    task.objectId,
    isChartReady,
    addData,
    drawAlertsFeedbacks
  ]);

  useEffect(() => {
    if (!socket || !chart || !isChartReady) {
      return;
    }

    const handleMessage = async (packet) => {
      const { data, taskId: packetTaskId, type: packetType, alert } = packet;

      if (task.objectId !== packetTaskId) {
        console.warn(`Wrong task received data for ${packetType}. Expected '${task.objectId}', but received '${packetTaskId}'.`);
        return;
      }

      switch (packetType) {
        case "INSIGHT_ADD":
          addData(data);
          break;

        case "CREATE_ALERT":
          if (packetTaskId !== task.objectId)
            break;

          setAlertsFeedbacksRef([...alertsFeedbacksRef.current, { type: "alert", title: alert.title, time: alert.time }]);
          addAlertChartMarker(chart, alert.time, getRangeIconName(alert.title), getRangeIconColor("alertSend"));
          break;

        case "ALERT_RESPONSE":
          const alertResponse = { ...alert, time: alert.responseTime, type: "alert response", title: alert.responseTitle, subject: alert.title }
          setAlertsFeedbacksRef([...alertsFeedbacksRef.current, alertResponse]);
          addAlertChartMarker(chart, alertResponse.responseTime, getRangeIconName(alertResponse.subject), getRangeIconColor(alert.responseTitle));
          break;

        case "CREATE_FEEDBACK":
          const feedback = { ...packet, type: "feedback", title: packet.title, time: packet.time };
          setAlertsFeedbacksRef([...alertsFeedbacksRef.current, feedback]);
          addAlertChartMarker(chart, packet.time, getRangeIconName(packet.title), getRangeIconColor("feedback"));
          break;

        case "CREATE_TRIGGER":
          setAlertsFeedbacksRef([...alertsFeedbacksRef.current, { type: "trigger", title: alert.title, time: alert.time }]);
          addAlertChartMarker(chart, alert.time, getRangeIconName(alert.title), getRangeIconColor("trigger"));
          break;

        default:
          break;
      }
    }

    socket.on("message", handleMessage);
    return () => socket.off("message", handleMessage)
  }, [
    socket,
    isChartReady,
    addAlertChartMarker,
    addData,
    task.objectId,
  ]);

  const handleSendAlert = (title) => {
    const series = channelSeries.values().next().value;

    if (!series) {
      return;
    }

    const lastPoint = series.getLastPoint();
    const lastPointTime = lastPoint["x"];

    const alertData = {
      title,
      taskId: task.objectId,
      time: lastPointTime,
    };

    sendAlert(alertData);
  }

  const handleTrigger = (title) => {
    const series = channelSeries.values().next().value;

    if (!series) {
      return;
    }

    const lastPoint = series.getLastPoint();
    const lastPointTime = lastPoint["x"];

    const triggerData = {
      title,
      taskId: task.objectId,
      time: lastPointTime,
    };

    sendTrigger(triggerData);
  }

  return (
    <div>
      <Chart
        id={id}
        key={`${id}-OVERLAY`}
        height={containerHeight}
        legendData={legendData}
        isLoading={state.isLoading}
        isEmpty={state.isEmpty}
        emptyStateMessage={"No insights data is available for this task, you may have to wait for real-time data!"}
      />

      {!(task.state === "COMPLETED") && !state.isEmpty && (
          <div className="d-flex flex-column justify-content-between">
            <EventMenu sendFunction={handleSendAlert} label="Alerts" options={ALERTS}/>
            <EventMenu sendFunction={handleTrigger} label="Triggers" options={TRIGGERS}/>
          </div>
      )}

      <AlertsTable className="mt-3" alerts={alertsFeedbacksRef.current} />
    </div>
  );
}
